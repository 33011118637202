export default function relativePathIfInternal (url: string|undefined): string {
	if (!url) {
		return '#';
	}

	if (url.startsWith('http') || url.startsWith('//')) {
		const urlObject = new URL(url);
		if (urlObject.host.includes('img.tantis') || urlObject.host.includes('resources.kfi')) {
			return urlObject.toString();
		}

		if (urlObject.host.includes('tantis.')) {
			return urlObject.pathname + urlObject.search + urlObject.hash;
		}

		return urlObject.toString();
	}

	if (!url.startsWith('/')) {
		return `/${url}`;
	}

	return url;
}
