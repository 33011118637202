import promotion from '@/ui/utils/dataLayer/promotion';
import isInViewport from '@/js/common/isInViewport';

export default function (
  place: string,
  element: HTMLElement,
): void {
  const {viewPromotion, selectPromotion} = promotion();
  const viewedSuggestions: string[] = [];
  element.querySelectorAll('.suggestion').forEach((suggestion, index) => {
    const label = suggestion.querySelector('.sg-title')?.textContent?.trim();
    document.addEventListener('scroll', () => {
      if (isInViewport((suggestion as unknown as HTMLElement)) && !viewedSuggestions.includes((suggestion as unknown as HTMLElement)?.dataset.suggestionId ?? '')) {
        viewPromotion({
          promotion_placement: 'suggestions',
          promotion_id: (suggestion as unknown as HTMLElement)?.dataset.suggestionId ?? '',
          promotion_type: 'banner-suggestions',
          promotion_name: suggestion.querySelector('a')?.title ?? '',
          index: index,
          element: suggestion.querySelector('a')?.querySelector('img')?.src ?? ''
        })
        viewedSuggestions.push((suggestion as unknown as HTMLElement).dataset.suggestionId ?? '');
      }
    })

    label && suggestion.querySelectorAll('a').forEach(
      (anchorElement) => {
        anchorElement.addEventListener(
          'click',
          () => {
            window.pushDataLayer({
              event: 'ux_events',
              event_place: place,
              event_category: 'click_box',
              event_label: label,
              index: index + 1,
            });
            selectPromotion({
              promotion_placement: 'suggestions',
              promotion_id: (suggestion as unknown as HTMLElement).dataset.suggestionId ?? '',
              promotion_type: 'banner-suggestions',
              promotion_name: anchorElement.title ?? '',
              index: index,
              element: anchorElement.querySelector('img')?.src ?? ''
            })
          }
        );
      }
    );
  });
}
