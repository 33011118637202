import {
	EcommercePromotionEventData,
	GoogleTagEvent, GoogleTagEventNames,
} from '@/typings/google-tag-manager';

export default function promotion () {
	function cleanUpEcommerce (): void {
		window.dataLayer.push({
			ecommerce: null,
		});
	}

	function viewPromotion (eventData: EcommercePromotionEventData): void {
		cleanUpEcommerce();
		window.pushDataLayer(
			{
				event: 'view_promotion' as GoogleTagEventNames,
				ecommerce: eventData,
			} as GoogleTagEvent,
		);
	}

	function selectPromotion (eventData: EcommercePromotionEventData, eventCallback?: Function): void {
		cleanUpEcommerce();
		window.pushDataLayer(
			{
				event: 'select_promotion' as GoogleTagEventNames,
				ecommerce: eventData,
				eventCallback: eventCallback,
			} as GoogleTagEvent,
		);
	}

	return {
		viewPromotion,
		selectPromotion,
	}
}
