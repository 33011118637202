import $ from 'jquery';
// Sets how much percentage of slider element has to be visible to fire an event
enum requiredVisiblePercentage {
  vertically = 0.8,
  horizontally = 0.8,
}
export default function isInViewport (element: HTMLElement|undefined): boolean {
  if (typeof element === 'undefined') {
    return false;
  }
  const navBarOffset = Number($('ui-page-header').height() || $('.ui-page-header').height());
  if (typeof element.getBoundingClientRect == 'undefined') {
    return false;
  }

  const rect = element.getBoundingClientRect();
  const requiredVisibilityOffsetHeight = rect.height * (1 - requiredVisiblePercentage.vertically);
  const requiredVisibilityOffsetWidth = rect.width * (1 - requiredVisiblePercentage.horizontally);

  return (
    rect.top >= navBarOffset - requiredVisibilityOffsetHeight &&
    rect.left >= 0 &&
    rect.bottom - requiredVisibilityOffsetHeight <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right - requiredVisibilityOffsetWidth <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
