import {SwiperContainer} from 'swiper11/swiper-element';
import {NavigationOptions} from 'swiper11/types/modules/navigation';
import {addClickEventsToSlider, addViewEventsToSlider} from '@/js/swiper/swiper-events-generator';
import {SwiperOptions} from 'swiper11/types/swiper-options';
import {register} from 'swiper11/element/bundle';
import {A11y, Navigation, Autoplay} from 'swiper11/modules';

register();
const swiper = document.querySelector('#mainSwiper') as unknown as SwiperContainer;
if (typeof swiper !== undefined) {
	const slidesCount = swiper?.querySelectorAll('.swiper-slide').length ?? 0;
	let navigation;
	if (document.body.classList.contains('desktop') && slidesCount > 1) {
		navigation = {
			enabled: true,
			nextEl: '.banner-slide-next',
			prevEl: '.banner-slide-prev',
		} as NavigationOptions;
	} else {
		navigation = {
			enabled: false,
			nextEl: undefined,
			prevEl: undefined,
		} as NavigationOptions;
	}
	const mainSwiperOpts: SwiperOptions = {
		modules: [
			Autoplay,
			Navigation,
			A11y,
		],
		autoplay: {
			delay: 3000,
		},
		speed: 1100,
		breakpoints: {
			0: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			1200: {
				slidesPerView: 1,
				spaceBetween: 2,
			},
		},
		freeMode: true,
		loop: slidesCount > 1,
		autoHeight: false,
		navigation: navigation,
		injectStylesUrls: [
			'/assets/home-banner.123123.css',
		],
	};

	const swiperName = 'Strona główna';
	Object.assign(swiper, mainSwiperOpts);
	swiper.initialize();
	addViewEventsToSlider(
		swiper,
		swiper.swiper,
		'view_slider_element',
		'banner',
		'header',
		true,
	);
	addClickEventsToSlider(
		swiper,
		swiper.swiper,
		'click_slider_element',
		'banner',
		'header',
		true,
	);
}
