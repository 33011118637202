import {SwiperContainer} from 'swiper11/swiper-element';

export default function getActiveIndex (swiper: SwiperContainer): number {
  if (typeof swiper.swiper === 'undefined') {
    return 0;
  }

  const slidesPerView: number = Number(swiper.swiper.params.slidesPerView) ?? 1;
  let indexRight = swiper.swiper.realIndex + slidesPerView;
  if (swiper.swiper.slides.length > 0 && swiper.swiper.slides.length < slidesPerView) {
    indexRight = swiper.swiper.slides.length;
  } else if (indexRight > swiper.swiper.slides.length) {
    indexRight = swiper.swiper.slides.length;
  }

  return indexRight - 1;
}
