/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PageLayout } from './PageLayout';

export type CmsLandingPage = {
    /**
     * The unique identifier of the landing page.
     */
    id?: string;
    /**
     * Internal name used for promotion names in analytics
     */
    internalName?: string;
    /**
     * Page layouts
     */
    pageLayouts?: Array<PageLayout>;
    /**
     * Landing page type enum
     */
    pageType?: CmsLandingPage.pageType;
    /**
     * Optional landing page title. Used as TITLE tag if landing page is on standalone URL
     */
    pageTitle?: string | null;
    /**
     * The identifier of author/publisher/serie/category/tag/product/etc.
     */
    entityId?: number | null;
};

export namespace CmsLandingPage {

    /**
     * Landing page type enum
     */
    export enum pageType {
        '_0' = 0,
        '_1' = 1,
    }


}

