import $ from 'jquery';
import {
	EcommerceItemListView,
	EcommerceSliderElement,
	GoogleTagEvent,
	GoogleTagEventNames, PromotionType,
} from '@/typings/google-tag-manager';
import isInViewport from '@/js/common/isInViewport';
import {SwiperContainer} from 'swiper11/swiper-element';
import promotion from '@/ui/utils/dataLayer/promotion';

const previouslyEmitted = {};

export function emitViewSliderElement (
	eventName: GoogleTagEventNames,
	swiper: SwiperContainer,
	slideIndex: number,
	promotionName?: string,
	promotionId?: number | string,
	promotionType?: PromotionType,
	promotionPlacement?: string,
	promotionEvents?: boolean,
): void {
	if (typeof swiper === 'undefined' || typeof slideIndex === 'undefined') {
		console.error('undefined index or element for slider');
		return;
	}

	if (typeof swiper.swiper.slides[slideIndex] === 'undefined') {
		console.error(`selected slide ${slideIndex} does not exist in swiper ${swiper.id}`);
		return;
	}

	let slideLinkTitle = swiper.swiper.slides[slideIndex].querySelector('a')?.getAttribute('title') as string;
	if (!slideLinkTitle) {
		slideLinkTitle = swiper.swiper.slides[slideIndex].querySelector('a')?.getAttribute('href') as string;
	}

	if (!slideLinkTitle && swiper.swiper.slides[slideIndex].shadowRoot?.host) {
		slideLinkTitle = swiper.swiper.slides[slideIndex].shadowRoot?.host?.querySelector('a')?.getAttribute('title') ?? swiper.swiper.slides[slideIndex].shadowRoot?.host?.querySelector('a')?.getAttribute('href') as string;
	}

	if (false === isInViewport(swiper)) {
		return;
	}

	if (isPreviouslyEmitted(`${swiper.id}-${slideIndex}`, slideIndex)) {
		return;
	}
	window.pushDataLayer({
			event: eventName,
			ecommerce: {
				creative_name: slideLinkTitle,
				promotion_name: promotionName,
				index: slideIndex + 1,
			} as EcommerceSliderElement,
		} as GoogleTagEvent,
	)

	if (promotionName && promotionId && promotionEvents) {
		const {viewPromotion} = promotion();
		viewPromotion(
			{
				promotion_name: promotionName.toLowerCase(),
				index: slideIndex + 1,
				promotion_id: promotionId,
				promotion_type: promotionType,
				promotion_placement: promotionPlacement ? promotionPlacement.toLowerCase() : undefined,
				element: slideLinkTitle,
			},
		);
	}

	setPreviouslyEmitted(`${swiper.id}-${slideIndex}`, [slideIndex]);
}

function isPreviouslyEmitted (componentTitle: string, index: number): boolean {
	return getPreviouslyEmitted(componentTitle) !== undefined && getPreviouslyEmitted(componentTitle).includes(index);
}

function setPreviouslyEmitted (componentTitle: string, indexes: Array<number>): void {
	(previouslyEmitted[componentTitle as keyof typeof previouslyEmitted] as Array<number>) = indexes;
}

function getPreviouslyEmitted (componentTitle: string): Array<number> {
	return previouslyEmitted[componentTitle as keyof typeof previouslyEmitted] as Array<number>
}
