import {
	emitViewSliderElement,
} from '@/js/swiper/swiper-event-emmiter';
import {SwiperContainer} from 'swiper11/swiper-element';
import {Swiper} from 'swiper11/types';
import $ from 'jquery';
import {
	EcommercePromotionEventData,
	EcommerceSliderElement,
	GoogleTagEvent,
	GoogleTagEventNames, PromotionType,
} from '@/typings/google-tag-manager';
import isInViewport from '@/js/common/isInViewport';
import getActiveIndex from '@/js/swiper/getActiveIndex';
import _ from 'lodash';
import promotion from '@/ui/utils/dataLayer/promotion';

export function addViewEventsToSlider (
	swiperContainer: SwiperContainer,
	swiper: Swiper,
	event: GoogleTagEventNames,
	promotionType: PromotionType,
	promotionPlacement: 'header' | 'content' | 'footer',
	promotionEvents: boolean,
): void {
	let indexRight = getActiveIndex(swiper);
	if (swiperContainer.parentElement && swiperContainer.parentElement.classList.contains('tab-pane')) {
		$('.cms-component button[data-toggle="tab"]').on('shown.bs.tab', function (event) {
			const tabSwiper = $(`${$(event.target).data('target')} #${swiperContainer.id}`);
			if (tabSwiper.attr('id') === swiperContainer.id) {

				emitViewSliderElement(
					'view_slider_element',
					swiperContainer,
					getActiveIndex(swiperContainer),
					swiper.slides[indexRight].dataset.promotionName,
					swiper.slides[indexRight].dataset.promotionId,
					promotionType,
					promotionPlacement,
					promotionEvents,
				);
			}
		})
	}

	swiper.on('slideChange', function () {
		const previousIndexRight = _.clone(indexRight);
		indexRight = getActiveIndex(swiperContainer);
		if (previousIndexRight < indexRight) {
			for (let i = previousIndexRight; i <= indexRight; i++) {

				emitViewSliderElement(
					'view_slider_element',
					swiperContainer,
					i,
					swiper.slides[i].dataset.promotionName,
					swiper.slides[i].dataset.promotionId,
					promotionType,
					promotionPlacement,
					promotionEvents,
				);
			}
		}
	});

	$(document).on('scroll', function () {
		if ((typeof swiperContainer !== 'object') ||
			(swiperContainer instanceof HTMLDivElement && !isInViewport(swiperContainer))) {
			return;
		}

		for (let i = 0; i <= getActiveIndex(swiperContainer); i++) {
			emitViewSliderElement(
				'view_slider_element',
				swiperContainer,
				i,
				swiper.slides[i].dataset.promotionName,
				swiper.slides[i].dataset.promotionId,
				promotionType,
				promotionPlacement,
				promotionEvents,
			);
		}
	})
}

export function addClickEventsToSlider (
	swiperContainer: SwiperContainer,
	swiper: Swiper,
	eventName: GoogleTagEventNames,
	promotionType: PromotionType,
	promotionPlacement: 'header' | 'content' | 'footer',
	promotionEvents: boolean,
): void {
	swiper.on('click', function (e: Swiper, event: MouseEvent) {
		if (event.target instanceof HTMLButtonElement) {
			return;
		}

		const clickedSlide = swiper.clickedSlide;
		let elementTitle = clickedSlide.shadowRoot?.host?.querySelector('a')?.getAttribute('title') as string;
		if (!elementTitle) {
			elementTitle = clickedSlide.shadowRoot?.host?.querySelector('a')?.getAttribute('href') as string;
		}

		window.pushDataLayer({
			event: eventName,
			ecommerce: {
				promotion_name: clickedSlide.dataset.promotionId?.toLowerCase(),
				promotion_id: clickedSlide.dataset.promotionName?.toLowerCase(),
				creative_name: elementTitle,
				index: swiper.clickedIndex + 1,
			} as EcommerceSliderElement,
		} as GoogleTagEvent);

		if (!promotionEvents) {
			return;
		}

		const {selectPromotion} = promotion()
		selectPromotion({
			promotion_id: clickedSlide.dataset.promotionId?.toLowerCase() ?? '',
			promotion_name: clickedSlide.dataset.promotionName?.toLowerCase() ?? '',
			promotion_type: promotionType,
			promotion_placement: promotionPlacement,
			element: elementTitle,
		})

	});
}
